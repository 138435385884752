'use client';
import React, { FC } from 'react';
import styled from 'styled-components';
import { LogoFull } from './LogoFull';
import { LogoMark } from './LogoMark';

export type LogoProps = {
  className?: string;
  variant?: 'small' | 'default';
  style?: React.CSSProperties;
};

const LogoWrapper = styled.div`
  min-height: 32px;
`;

export const Logo: FC<LogoProps> = ({ variant = 'default', ...props }) => (
  <LogoWrapper>
    {(() => {
      switch (variant) {
        case 'small':
          return <LogoMark {...props} />;
        case 'default':
        default:
          return <LogoFull {...props} />;
      }
    })()}
  </LogoWrapper>
);
