'use client';
import React, { FC } from 'react';

export const LogoMark: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_52_38)">
      <path
        d="M24.9824 16.1088C25.8016 16.1088 26.4928 15.8272 27.056 15.264C27.6192 14.7008 27.9008 14.0096 27.9008 13.1904C27.9008 12.3712 27.6192 11.68 27.056 11.1168C26.4928 10.5536 25.8016 10.272 24.9824 10.272C24.1632 10.272 23.472 10.5536 22.9088 11.1168C22.3456 11.68 22.064 12.3712 22.064 13.1904C22.064 14.0096 22.3456 14.7008 22.9088 15.264C23.472 15.8272 24.1632 16.1088 24.9824 16.1088Z"
        fill="#009FE3"
      />
      <path
        d="M6.76801 16.1088C7.58721 16.1088 8.27841 15.8272 8.84161 15.264C9.40481 14.7008 9.68641 14.0096 9.68641 13.1904C9.68641 12.3712 9.40481 11.68 8.84161 11.1168C8.27841 10.5536 7.58721 10.272 6.76801 10.272C5.94881 10.272 5.25761 10.5536 4.69441 11.1168C4.13121 11.68 3.84961 12.3712 3.84961 13.1904C3.84961 14.0096 4.13121 14.7008 4.69441 15.264C5.25761 15.8272 5.94881 16.1088 6.76801 16.1088Z"
        fill="#009640"
      />
      <path
        d="M26.9792 1.42721C25.8656 0.934414 24.4128 0.684814 22.6336 0.684814C19.8112 0.684814 17.5968 1.39521 16.0032 2.82241C14.4096 4.24961 13.6096 6.25921 13.6096 8.85761V20.2816V20.4544V21.4976C13.7504 21.5552 14.032 21.6448 14.4544 21.7728C14.8768 21.9008 15.344 21.9648 15.8496 21.9648C16.6688 21.9648 17.3024 21.7792 17.7568 21.4144C18.2112 21.0496 18.4352 20.48 18.4352 19.7184V19.0464V18.208V10.2656C18.4352 6.76481 20.0864 5.01121 23.3952 5.01121C24.2976 5.01121 25.104 5.10721 25.808 5.30561C26.512 5.50401 27.1072 5.71521 27.5872 5.93921C27.7536 5.82401 27.9776 5.54241 28.2464 5.09441C28.5152 4.64001 28.6496 4.17921 28.6496 3.69921C28.6496 2.68161 28.0928 1.92641 26.9792 1.42721Z"
        fill="#E30613"
      />
      <path
        d="M27.344 28.3072C26.3072 28.928 24.1056 29.856 22.5312 30.3424C20.4256 30.9632 18.4416 31.2256 15.6384 31.2256C12.5216 31.2256 9.9424 30.7392 7.1456 29.632C3.3696 28.128 2.4288 27.2384 2.4288 25.3824C2.4288 23.9232 3.2608 23.0784 4.208 23.0784C4.4256 23.0784 4.8352 23.2128 5.2768 23.4304C8.8288 25.2032 11.9008 26.4704 15.8304 26.4704C19.7344 26.4704 22.704 25.248 26.384 23.4304C26.8256 23.2064 27.2352 23.0784 27.4848 23.0784C28.4 23.0784 29.264 23.9232 29.264 25.3824C29.264 26.7584 28.8224 27.4688 27.344 28.3072Z"
        fill="#F39200"
      />
    </g>
    <defs>
      <clipPath id="clip0_52_38">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
