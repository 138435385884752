'use client';
import React, { ReactNode, forwardRef, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { StyledProps } from '@/types/helpers';

type ButtonType = 'button' | 'reset' | 'submit';

export type ButtonSize = 'L' | 'M' | 'S' | 'XS' | 'XXS';

export type ButtonProps = {
  id?: string;
  size: ButtonSize;
  variant: 'Primary' | 'Secondary' | 'Tertiary';
  color: 'Blue' | 'Red' | 'Black' | 'Gray' | 'LightBlue';
  label?: string | ReactNode;
  icon?: ReactNode;
  iconRight?: ReactNode;
  disabled?: boolean;
  type?: ButtonType;
  className?: string;
  fullWidth?: boolean;
  forceHovered?: boolean;
} & React.AriaAttributes &
  Pick<
    HTMLAttributes<HTMLButtonElement>,
    'onClick' | 'onMouseDown' | 'onMouseUp' | 'onMouseEnter' | 'onMouseLeave'
  >;

type StyledButtonProps = StyledProps<
  Pick<
    ButtonProps,
    'color' | 'size' | 'variant' | 'label' | 'fullWidth' | 'forceHovered'
  >
>;

const StyledButton = styled.button<StyledButtonProps>`
  text-transform: none;
  border-radius: 6px;
  border: 1px solid transparent;
  background-color: transparent;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  white-space: nowrap;

  /* Fonts */
  ${(p) => {
    if (p.$variant === 'Primary') {
      if (p.disabled || p['aria-disabled']) {
        switch (p.$size) {
          case 'XS':
          case 'S':
            return p.theme.texts.Caption2;
          case 'M':
          case 'L':
          default:
            return p.theme.texts.Paragraph2;
        }
      }

      switch (p.$size) {
        case 'XS':
        case 'S':
          return p.theme.texts.Caption1;
        case 'M':
        case 'L':
        default:
          return p.theme.texts.Title2;
      }
    }

    // Other variants
    switch (p.$size) {
      case 'XS':
      case 'S':
        return p.theme.texts.Caption2;
      case 'M':
      case 'L':
      default:
        return p.theme.texts.Paragraph2;
    }
  }}

  padding: ${(p) => {
    switch (p.$size) {
      case 'XXS':
      case 'XS':
        return p.$label ? '0px 7px' : '0px';
      case 'S':
        return p.$label ? '0px 15px' : '0px 3px';
      case 'M':
        return p.$label ? '0px 15px' : '0px 7px';
      case 'L':
      default:
        return p.$label ? '0px 15px' : '0px 11px';
    }
  }};

  /* Width */
  ${(p) =>
    p.$fullWidth &&
    css`
      width: 100%;
      justify-content: center;
    `}

  height: ${(p) => {
    switch (p.$size) {
      case 'XXS':
        return '16px';
      case 'XS':
        return '24px';
      case 'S':
        return '32px';
      case 'M':
        return '40px';
      case 'L':
      default:
        return '48px';
    }
  }};

  grid-gap: ${(p) => {
    switch (p.$size) {
      case 'XS':
      case 'S':
        return '4px';
      case 'M':
      case 'L':
      default:
        return '8px';
    }
  }};

  cursor: ${(p) => (p.disabled || p['aria-disabled'] ? 'default' : 'pointer')};

  /* Colors */
  ${(p) => {
    if (p.disabled || p['aria-disabled']) {
      if (p.$variant === 'Tertiary') {
        return css`
          color: ${p.theme.colors.base.Gray3};
          & svg {
            color: ${p.theme.colors.base.Gray4};
          }
        `;
      }

      return css`
        background-color: ${p.theme.colors.base.Gray5};
        color: ${p.theme.colors.base.Gray3};
        border-color: ${p.theme.colors.base.Gray4};
      `;
    }

    if (p.$variant === 'Primary') {
      switch (p.$color) {
        case 'Blue':
          const blueHoverCss = css`
            background-color: ${p.theme.colors.base.Blue2};
            border-color: ${p.theme.colors.base.Blue2};
          `;
          return css`
            background-color: ${p.theme.colors.base.Blue3};
            color: ${p.theme.colors.base.White1};
            border-color: ${p.theme.colors.base.Blue3};
            :hover {
              ${blueHoverCss}
            }
            ${p.$forceHovered && blueHoverCss}
          `;
        case 'Red':
          const redHoverCss = css`
            background-color: ${p.theme.colors.base.Red1};
            border-color: ${p.theme.colors.base.Red1};
          `;
          return css`
            background-color: ${p.theme.colors.base.Red2};
            color: ${p.theme.colors.base.White1};
            border-color: ${p.theme.colors.base.Red2};
            :hover {
              ${redHoverCss}
            }
            ${p.$forceHovered && redHoverCss}
          `;
        case 'LightBlue':
          const lightBlueHoverCss = css`
            background-color: ${p.theme.colors.base.Blue4};
            border-color: ${p.theme.colors.base.Blue4};
          `;
          return css`
            background-color: ${p.theme.colors.base.Blue5};
            color: ${p.theme.colors.base.Gray1};
            border-color: ${p.theme.colors.base.Blue5};
            :hover {
              ${lightBlueHoverCss}
            }
            ${p.$forceHovered && lightBlueHoverCss}
            & svg {
              color: ${p.theme.colors.base.Blue3};
            }
          `;
        default:
          return '';
      }
    }

    if (p.$variant === 'Secondary') {
      switch (p.$color) {
        case 'Blue':
          const blueHoverCss = css`
            border-color: ${p.theme.colors.base.Blue2};
          `;
          return css`
            background-color: ${p.theme.colors.base.White1};
            color: ${p.theme.colors.base.Blue2};
            border-color: ${p.theme.colors.base.Gray4};
            :hover {
              ${blueHoverCss}
            }
            ${p.$forceHovered && blueHoverCss}
            & svg {
              color: ${p.theme.colors.base.Blue3};
            }
          `;
        case 'Red':
          const redHoverCss = css`
            border-color: ${p.theme.colors.base.Red1};
          `;
          return css`
            background-color: ${p.theme.colors.base.White1};
            color: ${p.theme.colors.base.Red2};
            border-color: ${p.theme.colors.base.Gray4};
            :hover {
              ${redHoverCss}
            }
            ${p.$forceHovered && redHoverCss}
            & svg {
              color: ${p.theme.colors.base.Red2};
            }
          `;
        case 'Black':
          const blackHoverCss = css`
            border-color: ${p.theme.colors.base.Gray1};
          `;
          return css`
            background-color: ${p.theme.colors.base.White1};
            color: ${p.theme.colors.base.Gray1};
            border-color: ${p.theme.colors.base.Gray4};
            :hover {
              ${blackHoverCss}
            }
            ${p.$forceHovered && blackHoverCss}
            & svg {
              color: ${p.theme.colors.base.Gray3};
            }
          `;
        case 'Gray':
          const grayHoverCss = css`
            border-color: ${p.theme.colors.base.Gray1};
            color: ${p.theme.colors.base.Gray1};
            & svg {
              color: ${p.theme.colors.base.Gray3};
            }
          `;
          return css`
            background-color: ${p.theme.colors.base.White1};
            color: ${p.theme.colors.base.Gray2};
            border-color: ${p.theme.colors.base.Gray4};
            :hover {
              ${grayHoverCss}
            }
            ${p.$forceHovered && grayHoverCss}
            & svg {
              color: ${p.theme.colors.base.Gray3};
            }
          `;
        default:
          return '';
      }
    }

    if (p.$variant === 'Tertiary') {
      switch (p.$color) {
        case 'Blue':
          const blueHoverCss = css`
            text-decoration-line: underline;
            ${!p.$label &&
            css`
              border-color: ${p.theme.colors.base.Blue2};
            `}
          `;
          return css`
            color: ${p.theme.colors.base.Blue2};
            :hover {
              ${blueHoverCss}
            }
            ${p.$forceHovered && blueHoverCss}
            & svg {
              color: ${p.theme.colors.base.Blue3};
            }
          `;
        case 'Red':
          const redHoverCss = css`
            text-decoration-line: underline;
            ${!p.$label &&
            css`
              border-color: ${p.theme.colors.base.Red2};
            `}
          `;
          return css`
            color: ${p.theme.colors.base.Red2};
            :hover {
              ${redHoverCss}
            }
            ${p.$forceHovered && redHoverCss}
            & svg {
              color: ${p.theme.colors.base.Red2};
            }
          `;
        case 'Black':
          const blackHoverCss = css`
            text-decoration-line: underline;
            ${!p.$label &&
            p.$size !== 'XS' &&
            css`
              border-color: ${p.theme.colors.base.Gray3};
            `}
            ${p.$size === 'XS' &&
            css`
              & svg {
                color: ${p.theme.colors.base.Blue3};
              }
            `}
          `;
          return css`
            color: ${p.theme.colors.base.Gray1};
            :hover {
              ${blackHoverCss}
            }
            ${p.$forceHovered && blackHoverCss}
            & svg {
              color: ${p.theme.colors.base.Gray3};
            }
          `;
        case 'Gray':
          const grayHoverCss = css`
            text-decoration-line: underline;
            color: ${p.theme.colors.base.Gray1};
            & svg {
              color: ${p.theme.colors.base.Blue3};
            }
          `;
          return css`
            color: ${p.theme.colors.base.Gray2};
            :hover {
              ${grayHoverCss}
            }
            ${p.$forceHovered && grayHoverCss}
            & svg {
              color: ${p.theme.colors.base.Gray3};
            }
          `;
        default:
          return '';
      }
    }
  }}
`;

const Label = styled.span`
  display: contents;
`;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size,
      variant,
      label,
      color,
      icon,
      iconRight,
      type = 'button',
      className,
      fullWidth,
      forceHovered,
      ...props // pass down props like disabled or aria-disabled
    },
    ref
  ) => (
    <StyledButton
      $color={color}
      $size={size}
      $variant={variant}
      $label={label}
      type={type}
      className={className}
      $fullWidth={fullWidth}
      $forceHovered={forceHovered}
      ref={ref}
      {...props}
    >
      {icon}
      {!!label && <Label>{label}</Label>}
      {iconRight}
    </StyledButton>
  )
);
