'use client';
import React, { FC, ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@/common/buttons/Button';
import { Logo } from '@/common/Logo';
import { Text } from '@/common/Text';

type BaseErrorPageProps = {
  title: string;
  icon: ReactNode;
  description?: ReactNode;
  toHref?: string;
  toText?: string;
};

const StyledLogo = styled(Logo)`
  position: absolute;
  top: 27px;
  left: 24px;
`;

const Inner = styled.div`
  position: absolute;
  max-width: 470px;
  top: 160px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(p) => p.theme.colors.base.Gray2};
`;

const Title = styled(Text)`
  margin-top: 80px;
`;

const Description = styled(Text)`
  margin-top: 16px;
`;

const StyledLink = styled.a`
  margin-top: 24px;
`;

export const BaseErrorPage: FC<BaseErrorPageProps> = ({
  title,
  icon,
  description,
  toHref,
  toText,
}) => {
  useEffect(() => {
    window.document.title = `${title} | rellify`;
  }, [title]);

  return (
    <>
      <StyledLogo />
      <Inner>
        {icon}
        <Title variant="Headline1" align="Center" color="Gray1">
          {title}
        </Title>
        {!!description && (
          <Description variant="Paragraph1" align="Center" color="Gray2">
            {description}
          </Description>
        )}
        {!!toHref && !!toText && (
          <StyledLink href={toHref}>
            <Button variant="Primary" color="Blue" size="L" label={toText} />
          </StyledLink>
        )}
      </Inner>
    </>
  );
};
